<template>
  <div v-if="data" class="flix-container">
    <hr class="flix-html-hr" style="margin-top: 0"/>
    <flixExpand>
      <template v-slot:button>{{ $t('message.show', {name: data.length + ' ' + $tc("message.message", data.length)}) }}</template>
      <template v-slot:toggle>
        <a href="#" @click.prevent="callback(value)" class="flix-html-a" v-for="(value, index) in data" :key="value.ID">
          <messageListItem v-if="index < maxValues">
            <template v-slot:title><div class="flix-badge" style="display: inline-block">{{ data.length - index }} / {{ data.length }}</div> {{ value.title }}</template>
            <template v-slot:text><span v-html="value.text" /></template>
            <template v-slot:date><span v-html="value.date" /></template>
          </messageListItem>
        </a>
        <div class="flix-html-h2 flix-text-center" v-if="data.length > maxValues">...</div>
      </template>
      <template v-slot:expand v-if="data.length > maxValues">
        <a href="#" @click.prevent="callback(value)" class="flix-html-a" v-for="(value, index) in data" :key="value.ID">
          <messageListItem>
            <template v-slot:title><div class="flix-badge" style="display: inline-block">{{ data.length - index }} / {{ data.length }}</div> {{ value.title }}</template>
            <template v-slot:text><span v-html="value.text" /></template>
            <template v-slot:date><span v-html="value.date" /></template>
          </messageListItem>
        </a>
      </template>
    </flixExpand>
  </div>
</template>
<script>
export default {
  components: {
    messageListItem () { return import('@/components/messages/list') }
  },
  props: { data: Array, callback: Function },
  data () {
    return {
      maxValues: 3,
      unread: [],
      read: [],
      calendar: {}
    }
  },
  mounted () {
    this.getOrder()
  },
  methods: {
    getOrder () {
      JSON.parse(JSON.stringify(this.data)).forEach(function (val) {
        val.calendar = val.chat_nr.split('/')
        val.calendar = val.calendar[1]
        val.calendar = this.$store.getters.assistentsByID[val.calendar]
        val.view = JSON.parse(val.view)
        if (val.view.indexOf('bot') === -1) {
          this.unread.push(val)
        } else {
          this.read.push(val)
        }
      }.bind(this))
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
